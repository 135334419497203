import dynamic from 'next/dynamic'
import Link from 'next/link'

import { Figure } from '@/components/layout/content-components/serializers/figure'
import { Iframe } from '@/components/layout/content-components/serializers/iFrame'
import { Table } from '@/components/layout/content-components/serializers/table'
import { PDF } from '@/components/layout/content-components/serializers/pdf'
import { Youtube } from '@/components/layout/content-components/serializers/youtube'
import { FileDownload } from '@/components/layout/content-components/serializers/file-download'


// TODO: Might want to find a 'React' version to embed Vimeo videos
const DynamicComponentWithNoSSR = dynamic(
  () => import('./vimeo'),
  { ssr: false }
)



const serializers = {
  types: {
    mainImage(props) {
      return <Figure {...props.node} />
    },
    youtube(props) {
      return <Youtube {...props.node} />
    },
    vimeo(props) {
      return <DynamicComponentWithNoSSR {...props.node} />
    },
    pdfFile(props) {
      return props.node && props.node.file && props.node.file.asset && props.node.file.asset._ref ? <PDF {...props.node} /> : null
    },
    iframe(props) {
      return props.node && props.node.url ? <Iframe {...props.node} /> : null
    },
    anchor(props){
        return <span id={ props?.node?.anchorID?.current } />
    },
    table(props){
      return props?.node && props?.node?.rows?.length > 0 ? <Table {...props.node} /> : ''
    },
    // instagramPost(props) {
    //   return (<>instagramPost</>)
    // },
    // slideshow(props) {
    //   return props && props.node && props.node.slides ? <Slideshow {...props.node} /> : ''
    // },
    // imageGallery(props) {
    //   return props && props.node && props.node.images ? <Gallery {...props.node} /> : ''
    // },

    block: ({ node, children }) => {
      switch (node.style) {
       case 'h1':
           return <h1>{children}</h1>
           case 'h2':
           return <h2>{children}</h2>
           case 'h3':
           return <h3>{children}</h3>
           case 'h4':
           return <h4>{children}</h4>
           case 'h5':
           return <h5>{children}</h5>
           case 'h6':
            return <h6>{children}</h6>
     case 'blockquote':
            return <blockquote>{children}</blockquote>
           default:
              return <p>{children}</p>
      }
    }
  },

  marks: {
    text: (props) => {
      return 'test'
    },
    subTitle: ({ children }) =>
      <span className="subTitle">{children}</span>,
    fileDownload(props) {
      // console.log({props})
      switch (props?.mark?._type) {
        case 'fileDownload':
          if (props.mark.file && props.mark.file.asset && props.mark.file.asset._ref) {
            return <FileDownload {...props} />
          } else {
            return null
          }
      }
    },
    // eslint-disable-next-line react/display-name
    link: ({ mark, children }) => {
      const { blank, href } = mark
      if (!href) {
        return null
      }
      return blank === true ? (
        <>
          {href.includes('https') || href.includes('http') ? (
            <a href={href} target='_blank' rel='noopener noreferrer'>
              {children}
            </a>
          ) : (
            <a href={href}>{children}</a>
          )}
        </>
      ) : (
        <>
          {href.includes('https') || href.includes('http') || href.includes('tel') || href.includes('mailto') ? (
            <a href={href}>{children}</a>
          ) : (
            <Link href={href}>
              <a>{children}</a>
            </Link>
          )}
        </>
      )

    }
  },

  list: (props) => {
    switch (props.type) {
      case 'number': {
        return <ol className='prose md:prose-lg lg:prose-xl max-w-none'>{props.children}</ol>
      }
      case 'bullet': {
        return <ul className='prose md:prose-lg lg:prose-xl max-w-none'>{props.children}</ul>
      }
      default: {
        return <ul className='prose md:prose-lg lg:prose-xl max-w-none'>{props.children}</ul>
      }
    }
  }
}

export default serializers
